import { FormControl, InputLabel, Select, SelectProps } from "@mui/material"

export interface LabeledSelectProps<T>  {
    label: string
    fullWidth?: boolean
}

// eslint-disable-next-line import/no-anonymous-default-export
export default <T,>(props: LabeledSelectProps<T> & SelectProps<T>) => {
    const {label, fullWidth} = props
    const actualFullWidth = fullWidth === undefined ? true : fullWidth

    return (<FormControl fullWidth={actualFullWidth}>
        <InputLabel required={props.required}>{props.label}</InputLabel>
        <Select
            {...props}
            label={label}>
            {props.children}
        </Select>
    </FormControl>)
}