import { DPoint, UserInfo, VenueInfo } from "../Api"
import { get } from "../HttpMethods"

export interface SpotsSpaceDto {
    id: string
    name: string
    description: string
    address: string | undefined
    image: string
    hasPower: boolean
    hasWifi: boolean
    priceLevel: number
    status: string | undefined
    isActive: boolean
}
  
export interface HumanNeed {
    id: string
    name: string
}

export const allNeeds: HumanNeed[] = [
    { id: "creative-energy", name: "Creative Energy" },
    { id: "socialization", name: "Socialization" },
    { id: "quiet-work", name: "Quiet Work" },
    { id: "confidential-calls", name: "Confidential Calls" }
]

export interface BudgetLevel {
    id: string
    name: string
}
export const anyPrice:BudgetLevel = { id: "any", name: "Any" }
export const allBudgetLevels: BudgetLevel[] = [
    anyPrice,
    { id: "10", name: "<$10" },
    { id: "20", name: "$10-$20" },
    { id: "over-30", name: "$30+" }
]

export const budgetLevelNumber = (id:string):number => {
    const l = allBudgetLevels.find(b=>b.id = id)!!
    return allBudgetLevels.indexOf(l) + 1    
}

export const goodWifi = "Good"
export const allWifiLevels = ["Smokin'", goodWifi, "Sometimes", "don't care", ]
export const anyErgo = "Any"
export const allErgoLevels = [ anyErgo, "Desk/Table", "Barstool", "Lounge",]

export interface SimpleYearMonthDay {
    year:number
    month:number
    day:number
}

export type WorkMode = "computer" | "podcasting" | "film_processing" | "conference"

export interface SearchParams {
    date:SimpleYearMonthDay
    location: string
    until: string | undefined
    humanNeeds: string[] | undefined
    budget: string | undefined
    wifiLevel: string | undefined
    ergoLevel: string | undefined
    requiresPower: boolean
    requiresPrinter: boolean
    requiresDrink: boolean
    requiresFood: boolean
    requiresBathroom: boolean
    workMode:WorkMode
}
export interface ReservationSlotTimeOfDay{
    hour:number
    minute:number
}
export interface ReservationSlot {
    year:number,
    month:number,
    day:number,
    start:ReservationSlotTimeOfDay|undefined
    end:ReservationSlotTimeOfDay|undefined
    period?:"am"|"pm"|undefined
}
export interface ReservationRequest{
    spotId:string
    slots:ReservationSlot[]
}

export interface AdminReservationRequest{
    userIdentifier:string,
    reservation:SpotsReservation
}

export interface PublicUserIds{
    userId: string
    pseudonym:string[] | undefined
}

export interface AttachmentImageInfo{
    width:number,
    height:number
}

export interface MessageAttachmentInfoDTO{
     id: string,
     name:string|undefined,
     bytes:number|undefined,
     mimeType:string|undefined,
     imageInfo: AttachmentImageInfo|undefined
}

export interface ConversationStartContentDto{
    creator: PublicUserIds|undefined,
    text:String,
    attachments:MessageAttachmentInfoDTO[],
}

export interface SimpleConversationInfo{
    id:string,
    parentConversation:string|undefined,
    outsideConversation:string|undefined,
    name:string|undefined,
    routingMode:string,
    whenCreated:number,
    creatorUserId:string|undefined,
    canRemove:boolean,
    canBan:boolean,
    eventScheduleStart:number|undefined,
    eventScheduleEnd:number|undefined,
    preferredNotificationMode:string|undefined,
    visibilityModes:string[] | undefined,
    venue:VenueInfo|undefined,
    community:string|undefined,
    standardDiscoveryTime:number|undefined,
    startContent:ConversationStartContentDto|undefined,
    archivable:Boolean
}

export interface CommunityIdentity{
    id:string,
    flavor: string,
    name:string,
    description:string,
    avatarUrl:string|undefined,
}


export interface SlotReservation {
     id: string,
     orderId: string,
     spotId: string,
     slot:ReservationSlot
}


export interface SlotReservationWithContext{
    user:UserInfo
    reservation:SlotReservation
    space:SpotsSpaceDto
    spot:ReservableSpotType|undefined
    conversation: SimpleConversationInfo
    community: CommunityIdentity|undefined
}

export interface ReservationsOrderDto{
     id:string,
     reservations:SlotReservationWithContext[],
}

export interface WorkspotSubscription{
    userId:string,
    isActive:boolean
}


export interface SpotReservationOrderDetailsDto{
    reserver:UserInfo
    order: ReservationsOrderDto
}

export interface UserCreditInfo{
    userId:string,
    creditsAvailable:number,
    whenLastChanged:number | undefined,
    asOfWhen:number
}





export  const getUserReservations = (userId:string, fn:(result:ReservationsOrderDto[])=>void, onErr:(xhr:XMLHttpRequest)=>void)=> {
    get(`/user/${userId}/reservations`, 
        (text) => fn(JSON.parse(text)), 
        (xhr) => onErr(xhr))
}

export  const getUserReservationsPromise = (userId:string):Promise<ReservationsOrderDto[]>=> {
    return new Promise((resolveNormal, resolveError)=>{
        getUserReservations(userId, resolveNormal, (xhr)=>resolveError(`server said ${xhr.status}`))
    })
}





export interface DateTime {
    year: number
    month: number
    day: number
    hour: number
    minute: number
}
export interface WorkSlot {
    // name: string
    start: DateTime
    end: DateTime
    credits: number
}

export interface SpaceZoneDto{
    zoneId:string,
    description:string,
}
export interface SpaceOption {
    space: SpotsSpaceDto
    spots: ReservableSpotOption[]
    zones: SpaceZoneDto[]
    points: DPoint[]
}

export interface ReservableSpotOption {
    spotType: ReservableSpotType
    slots: WorkSlot[]
}

export interface ReservableSpotType {
    id: string
    name: string
    image: string
    zoneId:string|undefined
    description: string
    available: number
    hasPower: boolean
    hasWifi: boolean
    priceLevel: number
    rating: number
}
export interface StoredReservableSpotType{
    id:string,
    spaceId:string,
    zoneId:string|undefined,
    name:string,
    image:string,
    description:string,
    available:number,
    hasPower:boolean,
    wifiLevel:number,
    priceLevel:number,
    rating:number,
    schedule:SlotSchedule,
    autoApprove:boolean | undefined,
    modes:WorkMode[],
}

interface SlotSchedule{
    type:string
}

// export interface SlotTime{
//     hour:number
//     minute:number
// }
export interface StoredSlot{
    start:ReservationSlotTimeOfDay
    end:ReservationSlotTimeOfDay
    credits:number
}

export interface BasicSlotSchedule extends SlotSchedule {
    type:"basic"
    times:StoredSlot[]
}

export interface SpotSelection {
    spaceId: string
    spotId: string
}

export interface SpotsReservation {
    selections:Record<string, SpotSelection>
}
