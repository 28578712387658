import { CSSProperties, ReactElement, useState } from 'react'
import phonePicture from './darkphone.png';
import './App.css';
import _ from 'underscore'
import { Feature, isFeatureEnabled } from './Toggles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HouseIcon from '@mui/icons-material/House';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CommunityIcon from '@mui/icons-material/Diversity1';
import StadiumIcon from '@mui/icons-material/Stadium';
import SportsIcon from '@mui/icons-material/Sports';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import RoomIcon from '@mui/icons-material/Room';
import ChurchIcon from '@mui/icons-material/Church';
import shared from 'holler-shared';
import { Box, Stack, Typography } from '@mui/material';
import FooLayout from './FooLayout';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Breakpoint, isAtOrBelow, isLessThan, isMobile, setQueryParam, useBreakpoint, useViewport } from './Util';
import SearchControls from './workspots/SearchControls';
import { DownloadBox } from './DownloadTheApp';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

console.log("Hey, we have shared code from javascript!", new shared.fyi.holler.sharedclient.Greeting().greet())

interface NameLinkAndIcon {
    name: string
    link: string
    icon: ReactElement | undefined
    content?: () => ReactElement
}

interface TopLevelThing {
    id?:string
    name: string,
    tagline: string,
    background: string,
    content: () => ReactElement
}
const pathKeyFor = (i:TopLevelThing) => (i.id ?? i.name).toLowerCase()

const SubTabPanel = (props: { items: NameLinkAndIcon[] }) => {
    const { items } = props
    const breakpoint = useBreakpoint()
    const [selection, setSecondItem] = useState<NameLinkAndIcon>() //items[0]
    const isXs = isAtOrBelow(breakpoint, Breakpoint.xs)
    return (<>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6}>
                {/* <Stack style={{
                    marginTop: "40px",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderRadius: "5px",
                }}> */}

                <Grid container 
                    spacing={2}
                    style={{
                        marginTop: "40px",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        borderRadius: "5px",
                        }}
                >
                    {/* <div style={{
                    color: "white", 
                    fontSize: '5vw',
                    margin:"1vw",
                    }}>Discover</div> */}
                    {/* <div className="hook">Experience Together</div> */}
                    {items.map((next, idx) => {
                        const { name, link, icon } = next
                        const isRight = !isXs || ( (idx % 2 == 0) && icon)
                   
                    
                        return  <Grid xs={6} sm={12}><div
                            key={`link-${name}`}
                            style={{
                                margin: "10px",
                                fontSize: 'calc(12pt + 1vw)',
                                cursor: "pointer",
                                textAlign: isRight ? "right" : "left",
                                // border:"1px solid white",
                                // borderRadius:"10px",
                            }}>
                            {/* {isRight ? undefined : icon}
                            <Link to={link} style={{
                                display: "inline-block",
                                color: "white",
                                margin: "1vw",
                                textDecoration: (selection?.name == name ? "underline" : "none")
                            }}>{name}</Link>
                            {isRight ? icon : undefined} */}

                            <Link to={link} style={{
                                display: "inline-block",
                                color: "white",
                                margin: "1vw",
                                textDecoration: (selection?.name == name ? "underline" : "none")
                            }}><span style={{marginRight:"8px"}}>{name}</span>{icon}</Link>
                            

                        </div></Grid>
                    })
                    }
                </Grid>
                {/* </Stack> */}
            </Grid>
            <Grid xs={12} sm={6}>
                {selection?.content ? selection.content() : <Box style={{ margin: "40px" }}>{<DownloadBox showCompact={true} />}</Box>}
            </Grid>
        </Grid>
    </>)
}

export const hollerBlue = "#008ece"
export const organizerLink = "/organizers"
export const joinLink = "http://holler.fyi/join"
export const eventsLink = "/events"
export const spotsLink = "/workspots/spots"
export const downloadLink = "/download"

export default () => {
    const viewport = useViewport()
    const navigate = useNavigate()
    const { topNav } = useParams()

    const showMobile = isMobile()
    const { width, height } = useViewport()
    const showVertical = isMobile() || (width < 920)

    const screenshotStyle: CSSProperties = {
        minHeight: "500px",
        height: "50vmin",
        maxHeight: "900px", border: "0px solid grey",
        borderRadius: "20px", boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.5)", margin: "20px", marginTop: "0px"
    }

    const topLevelItems: TopLevelThing[] = [
        {
            id:"find-spots",
            name: "Spots",
            tagline: "Escape the house. Be Inspired.",
            background: "/coffee-shop.jpeg",
            content: () => <>

                <Stack style={{
                    margin: "20px",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(255,255,255,0.9)",
                }}>
                    <SearchControls onClick={(params) => {
                        navigate("/workspots/spots?search=" + encodeURIComponent(JSON.stringify(params)))
                    }} />
                </Stack>
            </>
        },
        {
            name: "Experiences",
            tagline: "Connect to The Moment",
            background: "/concert.jpg",
            content: () => <>
                <Box style={{
                    paddingLeft: showVertical ? "0px" : "20px",
                }}>
                    <SubTabPanel
                        items={[
                            {
                                name: "Events",
                                link: eventsLink,
                                icon: <CalendarTodayIcon htmlColor="white" />
                            },
                            {
                                name: "Venues",
                                link: spotsLink,
                                icon: <StadiumIcon htmlColor="white" />
                            },
                            {
                                name: "Experiences",
                                link: eventsLink,
                                icon: <RamenDiningIcon htmlColor="white" />
                            },
                            {
                                name: "Spots",
                                link: spotsLink,
                                icon: <RoomIcon htmlColor="white" />
                            },
                        ]} />
                </Box>
            </>
        },
        {
            name: "Groups",
            tagline: "Geniune Connection",
            background: "/coffee-shop.jpeg",
            content: () => <>
                <Box style={{
                    paddingLeft: showVertical ? "0px" : "20px",
                }}>
                    <SubTabPanel
                        items={[
                            {
                                name: "Communities",
                                link: downloadLink,
                                icon: <CommunityIcon htmlColor="white" />
                            },
                            {
                                name: "Teams",
                                link: downloadLink,
                                icon: <SportsIcon htmlColor="white" />
                            },
                            {
                                name: "Churches",
                                link: downloadLink,
                                icon: <ChurchIcon htmlColor="white" />
                            },
                            {
                                name: "Non-Profits",
                                link: downloadLink,
                                icon: <VolunteerActivismIcon htmlColor="white" />
                            },
                        ]} />
                </Box>
            </>
        },
        {
            name: "Everywhere",
            tagline: "Your digital connection to IRL ... it's Everywhere",
            background: "/concert.jpg",
            content: () => <>
                <Box style={{ marginTop: "40px", }}>
                    {(showMobile || showVertical) ? <DownloadBox showCompact={true} /> : <Grid container spacing={2} >
                        <Grid xs={12} md={6}>
                            <DownloadBox showCompact={true} />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <img style={screenshotStyle} src={phonePicture} />
                        </Grid>
                    </Grid>}
                </Box>

            </>
        },
    ]


    const topItem = topLevelItems.find(i => pathKeyFor(i) == topNav?.toLowerCase()) ?? topLevelItems[0]


    const selectTopLevel = (i: TopLevelThing) => {
        navigate(`/${pathKeyFor(i)}`)
    }


    const mainMenu = <>
        <Stack direction="row" spacing={3} style={{ marginTop: "20px", padding: "20px" }}>
            {topLevelItems.map(i => <>
                <Typography
                    variant="h2"
                    onClick={() => selectTopLevel(i)}
                    style={{
                        fontSize: "calc(8pt + 1.8vw)",
                        cursor: "pointer",
                        color: "white",
                        textDecoration: (i.name == topItem.name) ? "underline" : undefined
                    }}>{i.name}</Typography>
            </>)}
        </Stack>
    </>


    const content = (<>
        {mainMenu}
        <Box style={{
            textAlign: "left",
            paddingLeft: "10px",
            marginLeft: "10px",
            // borderLeft:"3px solid white",
        }}>
            <Typography
                variant="h2"
                style={{
                    fontSize: "calc(18pt + .5vw)",
                    color: "white",
                    fontStyle: "italic"
                }}>{topItem.tagline}</Typography>
        </Box>
        {topItem.content()}
        <div className="footer">
            <Link to="/privacy/">Privacy Policy</Link> | <Link to="/tos">Terms of Service</Link>
        </div>
    </>)


    return (<LocalizationProvider dateAdapter={AdapterMoment}>
        <FooLayout slogan={""} background={topItem.background} mode={"compact"}>
            {content}
        </FooLayout>
    </LocalizationProvider>)
}