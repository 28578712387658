import { CircularProgress, Stack} from '@mui/material';
import { lazy, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './HomePage';
import MainLayout from './MainLayout';

const PublicCodeViewer = lazy(() => import('./PublicCodeViewer'))
const EventCommunicationsPage = lazy(() => import('./EventCommunicationsPage'))
const WorkspotsApp = lazy(() => import('./workspots/WorkspotsApp'))
const ReservationStartScreen = lazy(() => import('./workspots/ReservationStartScreen'))
const ReservationPassScreen = lazy(() => import('./workspots/ReservationPassScreen'))
const WorkspaceAdminView = lazy(() => import('./workspots/WorkspaceAdminView'))
const WorkspotsAdminView = lazy(() => import('./workspots/WorkspotsAdminView'))
const SpotPostVisitPage = lazy(()=> import('./workspots/SpotPostVisitPage'))
const MeetingBooker = lazy(() => import('./MeetingBooker'))
const PublicCodeConfirmationScreen = lazy(() => import('./PublicCodeConfirmationScreen'))
const Toggles = lazy(() => import('./Toggles'))
const EventStartPage = lazy(() => import('./EventStartPage'))
const EventsPage = lazy(() => import('./EventsPage'))
const ManageSubscriptionPage = lazy(() => import('./ManageSubscriptionPage'))
const DownloadPage = lazy(() => import('./DownloadPage'))
const EventsLandingPage = lazy(() => import('./EventsLandingPage'))
const DeleteAccountPage = lazy(() => import('./DeleteAccountPage'))

export default () => {
  return (<Suspense fallback={<CircularProgress />}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/events" element={<EventsLandingPage/>} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/organizers" element={<EventsPage />} />

        <Route path="/delete-my-account" element={<DeleteAccountPage/>} />

        <Route path="/test-links" element={<TestLinksPage />} />

        <Route path="/event-start" element={<EventStartPage />} />
        <Route path="/event-communications" element={<EventCommunicationsPage />} />
        <Route path="/i/*" element={<PublicCodesPage />} />
        <Route path="/u/*" element={<PublicCodesPage />} />
        <Route path="/x/*" element={<PublicCodesPage />} />
        <Route path="/c/*" element={<PublicCodeConfirmationPage />} />
        <Route path="/r/:reservationId" element={<ReservationPassScreen />} />
        <Route path="/book/*" element={<PersonalBookingPage />} />
        <Route path="/toggles" element={<Toggles />} />
        <Route path="/workspots/*" element={<WorkspotsApp />} />
        <Route path="/workspot-reservation/*" element={<ReservationStartScreen 
            pathToMe='/workspot-reservation'
            onSuccess={()=>console.log("doing nothing here, since the native app will take-over")}/>} />
        <Route path="/admin" element={<WorkspotsAdminView />} />
        <Route path="/workspot-admin/:spaceId" element={<WorkspaceAdminView />} />
        <Route path="/workspot-feedback/:visitId" element={<SpotPostVisitPage/>} />
        <Route path="/my-subscription" element={<ManageSubscriptionPage />} />
        <Route path="/:topNav" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  </Suspense>)
}

const PersonalBookingPage = () => (
  <MainLayout slogan={"Meetings"} background={"/coffee-shop.jpeg"} mode={"compact"}>
    <MeetingBooker />
  </MainLayout>)

const PublicCodeConfirmationPage = () => (
  <MainLayout slogan={"You're Invited!"} background={undefined} mode={"large"}>
    <PublicCodeConfirmationScreen />
  </MainLayout>)

const PublicCodesPage = () => (
    <PublicCodeViewer />
  )

const TagLink = (props:{tagId:string}) => <a href={`https://holler.fyi/x/${props.tagId}`}>{props.tagId}</a>

const TestLinksPage = () => (
  <MainLayout slogan={"You're Invited!"} background={undefined} mode={"compact"}>
    <Stack spacing={3} style={{background:"white"}}>
    <a href="https://holler.fyi/download">Download</a>
    <TagLink tagId="testing123"/>
    </Stack>
  </MainLayout>)