export type Payload = Document | XMLHttpRequestBodyInit | null

export function send(method:HttpMethodName, payload: Payload, url: string, fn: (responseText: string) => void, err: (err: XMLHttpRequest) => void) {
  var xhttp = new XMLHttpRequest();
  xhttp.withCredentials = true;
  xhttp.onreadystatechange = function () {
    //console.log("Fetching ...")
    if (this.readyState === 4) {
      if (this.status === 200) {
        fn(xhttp.responseText);
      } else {
        if (err) err(xhttp)
      }
    }
  };
  xhttp.open(method, url, true);
  xhttp.send(payload);
}

export function post(payload: Payload, url: string, fn: (responseText: string) => void, err: (err: XMLHttpRequest) => void) {
  send("POST", payload, url, fn, err);
}

export function put(payload: Payload, url: string, fn: (responseText: string) => void, err: (err: XMLHttpRequest) => void) {
  send("PUT", payload, url, fn, err);
}

export function get(url: string, fn: (responseText: string) => void, err: (err: XMLHttpRequest) => void) {
  var xhttp = new XMLHttpRequest();
  xhttp.withCredentials = true;
  xhttp.onreadystatechange = function () {
    //console.log("Fetching ...")
    if (this.readyState === 4) {
      if (this.status === 200) {
        fn(xhttp.responseText);
      } else {
        if (err) err(xhttp)
      }
    }
  };
  xhttp.open("GET", url, true);
  xhttp.send();
}

type HttpMethodName = "GET" | "POST" | "PUT" | "PATCH"

export function sendPromise(method:HttpMethodName, payload: Payload|undefined, url: string):Promise<string>  {

  return new Promise((resolveSuccess, resolveFailure)=>{

      var xhttp = new XMLHttpRequest();
      xhttp.withCredentials = true;
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            resolveSuccess(xhttp.responseText);
          } else {
            resolveFailure(`${xhttp.status} ${xhttp.statusText}: ${xhttp.responseText}`)
          }
        }
      };
      xhttp.open(method, url, true);
      xhttp.send(payload);
  })

}

export const getPromise = (url: string):Promise<string> => sendPromise("GET", undefined, url)