import { ReactNode } from "react";
import logo from './logo-banner-with-white-text-1024.png';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Box, Button, Stack, ThemeProvider, Typography, createTheme } from "@mui/material";
import { containerStyles, headerAndCenterStyles } from "./MainLayout";

export type PageMode = "large" | "compact"
export default (props: { slogan: ReactNode, children: ReactNode, background: string | undefined, mode: PageMode }) => {
  const { slogan, children } = props
  const navigate = useNavigate()

  const background = props.background || "/concert.jpg"
  const goHome = () => {
    console.log("Going home")
    navigate("/");
  }

  const baseLogoStyles = {
    display: "inline-block",
    cursor: "pointer"
  }

  const logoModes: Record<string, Record<string, any>> = {
    "large": { ...baseLogoStyles, width: "80%" },
    "compact": { ...baseLogoStyles, height: "60px" }
  }


  const links: Record<string, string> = {}

  const logoStyles = logoModes[props.mode]
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008ece",
      },
      // secondary: {
      //   main: green[500],
      // },
    },
  });
  return (

    <div className="container-background" style={{
      backgroundImage: `url(${background})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      minHeight: "100vh"
    }}>

      <div style={containerStyles}>

        <div style={{
          display: "inline-block",
          width: "100%",
          maxWidth: "800px",
        }}>
          <div style={{
            textAlign: "left",
            paddingTop: "15px",
            marginLeft: "20px",
            marginRight: "20px",
          }}>

            <Grid container spacing={2}>
              <Grid xs={6}>

                <img className="logo" style={logoStyles} onClick={goHome} src={logo} />
              </Grid>
              <Grid xs={6} >
                {slogan && <div className="slogan-holder">
                  {Object.keys(links).map(name => {
                    return <span style={{
                      margin: "10px",
                      fontSize: '3vw',
                      cursor: "pointer"
                    }}><a style={{ textDecoration: "none", color: "white" }} href={links[name]}>{name}</a></span>
                  })

                  }
                  <div className="slogan">{slogan}</div>
                </div>}
                {!slogan && <Stack direction="row-reverse" style={{ marginLeft: "40px" }}>
                  <Stack direction="row" spacing={1}>
                    <ThemeProvider theme={theme}>
                      <Button variant="contained" onClick={()=>window.open("https://www.holler.fyi/join", '_blank')}>Join <br/> Event</Button>
                    </ThemeProvider>
                  </Stack>
                </Stack>}
              </Grid>
            </Grid>

            {/* <img className="logo" style={logoStyles} onClick={goHome} src={logo}/> */}

          </div>
        </div>

        <div style={{paddingBottom:"15px"}}>
          <div style={headerAndCenterStyles}>
            <div>
              {children}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
