import { Box } from "@mui/material"
import { ReactNode } from "react"


export default (props: {maxWidth:number|string, children?: ReactNode }) => {
    const {maxWidth, children} = props

    return (<>
        <Box style={{ textAlign: "center" }}>
            <Box
                style={{
                    maxWidth: maxWidth,
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                {children}
            </Box>
        </Box>
    </>)
}