import React, { SetStateAction } from "react"
import { useElementSize } from "usehooks-ts"

export const lastPathSegment = (): string | undefined => {
    var pathSegments = window.location.pathname.split("/")
    return pathSegments[pathSegments.length - 1]
}

export const queryParam = (name: string): string | undefined => {
    const params = new URLSearchParams(window.location.search)
    return params.get(name) || undefined
}

export const setQueryParam = (name: string, value: string) => {
    const params = new URLSearchParams(window.location.search)
    params.set(name, value)
    window.history.replaceState(null, "", window.location.pathname + "?" + params.toString())
}

export function getParam(key: string): string | undefined {
    var params = new URLSearchParams(window.location.search);
    const val = params.get(key)
    return val || undefined
}
export function setParam(key: string, value: string) {
    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value. 
    queryParams.set(key, value);

    // Replace current querystring with the new one.
    (window.history).replaceState(null, "", "?" + queryParams.toString());
}

export const getJsonParam = <T,>(key: string): T | undefined => {
    const json = getParam(key)
    return json ? JSON.parse(json) : undefined
}
export const setJsonParam = <T,>(key: string, v: T) => {
    setParam(key, JSON.stringify(v))
}
export const useJsonParam = <T,>(key: string): [T | undefined, (value: T) => void] => {
    const [rev, setRev] = React.useState(0)

    return [
        getJsonParam(key),
        (v) => {
            setRev(rev + 1)
            setJsonParam(key, v)
        },
    ]
}

export const onlyDefined = <T,>(item: T | undefined): item is T => {
    return !!item
}

export const mapRange = <T>(n: number, fn: (n: number) => T): T[] => {
    let results: T[] = []

    for (let x = 0; x < n; x++) {
        results.push(fn(x))
    }

    return results
}

export const padLeadingZeros = (numZeros:number, value:any):string => {
    const str = `${value}`
    var result = str
    while(result.length < (numZeros)){
        result = `0${result}`
    }
    return result
}

//https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
export const isMobile = (): boolean => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

// REACT HOOKS


interface WindowSize {
    width: number
    height: number
}
// https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/
export const useViewport = (): WindowSize => {

    const getSize = (): WindowSize => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const [size, setSize] = React.useState<WindowSize>(getSize());

    React.useEffect(() => {
        const handleWindowResize = () => setSize(getSize());
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return size;
}

export const isUndefined =(a:any)=> typeof a !== 'undefined'

export const useComponentSize = ():{ref: (node: Element | null) => void, size:WindowSize|undefined} => {

    const { ref, height, width } =  useElementSize()

    const size:WindowSize|undefined = ((typeof height !== 'undefined') && (typeof width !== 'undefined')) ? {width, height} : undefined

    return {ref, size}

}

export enum Breakpoint {
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl",
}
const breakpoints = [
    Breakpoint.xs,
    Breakpoint.sm,
    Breakpoint.md,
    Breakpoint.lg,
    Breakpoint.xl,
]
export const isLargerThan = (b:Breakpoint, ref:Breakpoint):boolean => breakpoints.indexOf(b) > breakpoints.indexOf(ref)

export const isLessThan = (b:Breakpoint, ref:Breakpoint):boolean => breakpoints.indexOf(b) < breakpoints.indexOf(ref)
export const isAtOrBelow = (b:Breakpoint, ref:Breakpoint):boolean => {
    const idxB = breakpoints.indexOf(b)
    const idxRef = breakpoints.indexOf(ref)
    console.log("size comparison: ", idxB, idxRef)
    return idxB <= idxRef
}

export const getBreakPoint = (size:{width:number, height:number}):Breakpoint=>{
    const {width, height} = size

    if(width < 600){
        return Breakpoint.xs
    }else if(width < 960){
        return Breakpoint.sm
    }else if(width < 1280){
        return Breakpoint.md
    }else if(width < 1920){
        return Breakpoint.lg
    }else{
        return Breakpoint.xl
    }

}

export const useBreakpoint = ():Breakpoint=> getBreakPoint(useViewport())

export const useQueryPersistedState = <T,>(name: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const queryValue = queryParam(name)
    const parsedQueryValue: T = queryValue && JSON.parse(queryValue)
    console.log("Parsed query value is ", parsedQueryValue)
    const [value, setValue] = React.useState(parsedQueryValue || initialValue)

    const wrappedSetState: React.Dispatch<SetStateAction<T>> = (action: SetStateAction<T>) => {
        setValue(prevValue => {

            let newValue: T
            if (typeof action == "function") {
                newValue = (action as (prevValue: T) => T)(prevValue)
            } else {
                newValue = action
            }
            setQueryParam(name, JSON.stringify(newValue))
            return newValue
        })
    }

    return [value, wrappedSetState]
}

export const useInterval = (callback: (isCancelled: () => boolean) => void, interval: number, immediate: boolean) => {
    const ref = React.useRef<(isCancelled: () => boolean) => void>();

    // keep reference to callback without restarting the interval
    React.useEffect(() => {
        ref.current = callback;
    }, [callback]);

    React.useEffect(() => {
        // when this flag is set, closure is stale
        let cancelled = false;

        // wrap callback to pass isCancelled getter as an argument
        const fn = () => {

            ref.current && ref.current(() => cancelled);
        };

        // set interval and run immediately if requested
        const id = setInterval(fn, interval);
        if (immediate) fn();

        // define cleanup logic that runs
        // when component is unmounting
        // or when or interval or immediate have changed
        return () => {
            cancelled = true;
            clearInterval(id);
        };
    }, [interval, immediate]);
};

export const associate = <T,V,>(needIds:T[], extractor:(n:T)=>[string, V]):Record<string, V>=>{
   
    const result:Record<string, V> = {}

    needIds?.forEach(e=>{
        const [key, value] = extractor(e)
        result[key] = value
    })

    return result
}

export const handleEnterPressed = (enterAction:()=>void):React.KeyboardEventHandler<HTMLDivElement> => {
    return (e)=> e.keyCode === 13 ? enterAction() : null
}
