package fyi.holler.sharedclient

import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@ExperimentalJsExport
class Greeting {
    private val platform: Platform = getPlatform()

    fun greet(): String {
        return "Hello from platform-specific code (${platform.name}).  boo hoo doo goo poo roo!"
    }

    @Suppress("NON_EXPORTABLE_TYPE")
    fun funnyNames():List<String>{
        return listOf("barry", "Sam")
    }
}