import { display } from "@mui/system";
import { CSSProperties, ReactNode } from "react";
import logo from './logo-banner-with-white-text-1024.png';
import { Feature, isFeatureEnabled } from "./Toggles";
import { useNavigate } from "react-router-dom";


// .header-band, .center
export const headerAndCenterStyles:CSSProperties = {
  display: "inline-block",
  width:"100%",
  maxWidth: "800px",
}

export const containerStyles:CSSProperties = {
  backgroundColor: "rgb(0 0 0 / 65%)",
  textAlign: "center",
  width: "100%",
  minHeight: "100vh"
}

export type PageMode = "large" | "compact"
export default (props:{slogan:string, children:ReactNode, background:string|undefined, mode:PageMode})=>{
    const {slogan, children} = props
    const navigate = useNavigate()
  
    const background = props.background || "/concert.jpg"
    const goHome = ()=>{
      console.log("Going home")
      navigate("/");
    }
  
    const baseLogoStyles = {
        display: "inline-block",
        cursor: "pointer"    
    }

    const logoModes:Record<string, Record<string,any>> = {
        "large":{... baseLogoStyles, width:"80%"},
        "compact":{... baseLogoStyles, height:"60px"}
    }

    const links:Record<string, string> = {}

    const logoStyles = logoModes[props.mode]

    return (
  
      <div className="container-background" style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        minHeight: "100vh"
      }}>
      
        <div style={containerStyles}>
      
          <div style={headerAndCenterStyles}>
            <div className="header">
              
              <img className="logo" style={logoStyles} onClick={goHome} src={logo}/>
              
              <div className="slogan-holder">
              {Object.keys(links).map(name=>{
                return <span style={{
                  margin:"10px",
                  fontSize: '3vw',
                  cursor:"pointer"
                }}><a style={{textDecoration: "none", color: "white"}} href={links[name] }>{name}</a></span>
              })

              }
                <div className="slogan">{slogan}</div>  
              </div>
            </div>
          </div>
          {/* <div class="slogan2" >Your Event, Connected!</div>   */}
          
          <div style={{paddingBottom:"15px"}}>
            <div style={headerAndCenterStyles}>
              <div>
                {children}
              </div>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
  
