
import playStoreImage from './play-store-badge.png'
import appStoreImage from './appstore.png'
import joinQrCode from './join-qr-code.png'
import { Stack } from '@mui/system'
import { useUtils } from '@mui/x-date-pickers/internals'
import { isMobile, useViewport } from './Util'
import { Link, Paper, Typography } from '@mui/material'
import { CSSProperties, ReactElement, ReactNode } from 'react'


export const DownloadBox = (props:{showCompact:boolean}) => {
    const {showCompact} = props
    const downloadButtonStyles = { maxWidth: "400px" }

    const {width, height} = useViewport()
    
    const showMobile = isMobile()
    return (<>
        {!showMobile && <>
            <div style={{ textAlign: "center" }}>
                <Paper elevation={3} style={{ display: "inline-block", maxWidth: "30vmin", padding: "34px" }}>
                    <Stack style={{}}>

                        <Typography gutterBottom variant="h5" component="div">
                            Point your camera at the QR code to download the Holler app
                        </Typography>
                        <div style={{ display: "inline-block" }}></div>
                        <img style={{  maxHeight: "30vmin" }} src={joinQrCode} />
                        <div>On a mobile device?  <Link href="https://www.holler.fyi/join">Get the app</Link> </div>
                    </Stack>
                </Paper>
            </div></>}
        {showMobile && <div>
            <div className="store-badge" >
                <a href="https://apps.apple.com/us/app/holler-talk-here-now/id1482056197">
                    <img style={downloadButtonStyles} alt="Download on the App Store" src={appStoreImage}></img></a>
            </div>
            <div className="store-badge" >
                <a href='https://play.google.com/store/apps/details?id=fyi.holler.android_holler&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style={downloadButtonStyles} alt='Get it on Google Play' src={playStoreImage} /></a>
            </div>
        </div>}</>)
}

export default (props: { mainInstruction: string, confirmationDetails: string, moreDetails?: ReactNode }) => {
    const { mainInstruction, confirmationDetails, moreDetails } = props

    const {width, height} = useViewport()


    const showVertical = isMobile() || (width<620)

    const screenshotStyle: CSSProperties = { minHeight:"500px", height:"50vmin", maxHeight: "900px", border: "0px solid grey", borderRadius: "20px", boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.5)", margin: "20px", marginTop:"5px" }
    const show2Screenshots = showVertical ? width>900 : width > 1100

    return (<div style={{marginRight:"40px", marginLeft:"40px"}}>

        <div style={{ fontSize: "1.4em", fontStyle: "italic", marginLeft: "10px", marginRight: "10px" }}>{confirmationDetails}</div>
        
        <Stack spacing={5} >
            <Typography gutterBottom variant="h2" component="div">
                {mainInstruction}
            </Typography>
            {moreDetails && <div style={{ fontSize: "1.4em", fontStyle: "italic", marginLeft: "10px", marginRight: "10px" }}>{moreDetails}</div>}
            <div>
            <div style={{display:"inline-block"}}>
                <Stack direction={showVertical? "column": "row" } spacing={5}>
                    <DownloadBox showCompact={showVertical}/>
                    <div style={{textAlign:"center", }}>
                        <Stack direction="row" style={{maxWidth:"100vw",  display:"inline-block"}} >
                            {/* <img src="/workspots-demo/holler-workspots-app-demo-reservation-confirmation.900h.png" style={screenshotStyle}/> */}
                            <img src="/workspots-demo/holler-workspots-app-demo-select-slot.900h.png" style={{... screenshotStyle, zIndex:"3"}} />
                            {show2Screenshots && <img src="/workspots-demo/holler-workspots-app-demo-destinations-tab.900h.png" style={{... screenshotStyle, marginLeft:"-40px", zIndex:"2"}} />}
                        </Stack>
                    </div>
                </Stack>
            </div>
            </div>
        </Stack>
    </div>)
}