import React, { CSSProperties } from 'react';
import './App.css';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, RadioGroupProps, } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export interface OptionsListProps<T> {
    label: string,
    disabled?:boolean,
    optionLabel: (v: T) => string,
    options: T[],
    value: Record<string, boolean> | undefined,
    optionKey: (v: T) => string,
    setValue: (v:Record<string, boolean>) => void
}

export function OptionsList<T>(props: OptionsListProps<T>) {
    const { label, disabled, value, optionLabel, optionKey, setValue, options } = props
    const results = value ?? {}

    return (<>
        <FormLabel>{label}</FormLabel>
        <FormGroup>
            {options.map(item => {
                const key = optionKey(item)
                const isChecked:boolean = results[key] ?? false
                return <DiscreteOption2 key={key} disabled={disabled} label={optionLabel(item)} value={isChecked} setValue={checked=>{

                    let foo:Record<string, boolean> = {
                        ... results
                    }
                    foo[key] = checked
                    
                    setValue(foo)
                }} />
            })}
        </FormGroup></>)
}

export interface SimpleRadioGroupProps<T> extends RadioGroupProps {
    optionLabel: (v: T) => string,
    disabled?:boolean,
    options: T[],
    value: T | undefined,
    optionKey: (v: T) => string,
    setValue: React.Dispatch<React.SetStateAction<T | undefined>>
}
export const SimpleRadioGroup = <T,>(props:SimpleRadioGroupProps<T>)=>{
    const { value, optionLabel, disabled, optionKey, setValue, options } = props
    return (
        <RadioGroup
            {... props}
            value={value ? optionKey(value) : null}
            onChange={e => {
                const v = props.options.find(n => optionKey(n) === e.target.value)!
                console.log("value", v)
                setValue(v)
            }}
        >
            {options.map(n =>
                <FormControlLabel key={optionKey(n)} value={optionKey(n)} control={<Radio disabled={disabled}/>} label={optionLabel(n)} />
            )}
        </RadioGroup>)
}

export interface ExclusiveListProps<T> {
    label: string,
    disabled?:boolean,
    labelStyle?:CSSProperties,
    optionLabel: (v: T) => string,
    options: T[],
    value: T | undefined,
    optionKey: (v: T) => string,
    setValue: React.Dispatch<React.SetStateAction<T | undefined>>
}

export function ExclusiveList<T>(props: ExclusiveListProps<T>) {
    const { label, labelStyle, value, optionLabel, optionKey, setValue, options } = props
    // console.log("value", value)
    const labelId = `${props.label}-label`
    return (<>
        <FormLabel id={labelId} style={labelStyle}>{props.label}</FormLabel>
        <SimpleRadioGroup 
            {... props}
            aria-labelledby={labelId}
            />
    </>)
}

export const DiscreteOption = (props: { label: string, value: boolean | undefined, setValue: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { label, value, setValue } = props

    return <FormControlLabel control={<Checkbox checked={value} onChange={e => setValue(e.target.checked)} />} label={label} />
}


export const DiscreteOption2 = (props: {label: string, value: boolean | undefined, disabled?:boolean, setValue: (val:boolean)=>void }) => {
    const { label, value, disabled, setValue } = props

    return <FormControlLabel key={label} control={<Checkbox checked={value} disabled={disabled} onChange={e => setValue(e.target.checked)} />} label={label} />
}