import React, { CSSProperties, ReactNode } from 'react';
import './App.css';
import { url } from 'inspector';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Autocomplete, SxProps, Box, Button, Divider, FormControl, FormGroup, FormLabel, IconButton, Input, InputAdornment, InputLabel, Paper, TextField, Typography, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Stack } from '@mui/system';
import TuneIcon from '@mui/icons-material/Tune';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment'
import { allBudgetLevels, allErgoLevels, allNeeds, allWifiLevels, anyErgo, BudgetLevel, goodWifi, HumanNeed, SearchParams, WorkMode } from './Api';
import { DiscreteOption, ExclusiveList, OptionsList } from './CommonControls';
import { DatePicker } from '@mui/x-date-pickers';
import { DateField } from '@mui/x-date-pickers';
import { dateToMoment, momentToDate, today } from '../Api-Util';
import LabeledSelect from '../LabeledSelect';
import NearMeIcon from '@mui/icons-material/NearMe';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Theme } from '@emotion/react';
import { associate, Breakpoint, getBreakPoint, isAtOrBelow, isMobile, useBreakpoint, useComponentSize } from '../Util';
import CenteredMaxWidthContentBox from './CenteredMaxWidthContentBox';
import { useElementSize } from 'usehooks-ts';

export interface SearchControlsParams { value?:SearchParams, hideButton?: boolean, showFull?:boolean, onClick: (params: SearchParams) => void }

export interface WorkModeWrapper {
    id:WorkMode
    label:string
}

export const ComputerWork:WorkModeWrapper = {
    id:"computer",
    label:"Remote Work",
}
export const PodCasting:WorkModeWrapper = {
    id:"podcasting",
    label:"Podcasting & Audio",
}

export const FilmProcessing:WorkModeWrapper = {
    id:"film_processing",
    label:"Film Processing",
}

export const InPersonConference:WorkModeWrapper = {
    id:"conference",
    label:"On-Site Meeting",
}

export const allWorkModes = [
    ComputerWork,
    InPersonConference,  
    PodCasting,
    FilmProcessing,      
]

export const TitledSearchControlsPanel = (props: SearchControlsParams) => {
    return (<>
        <Typography variant="h4" style={{ fontWeight: "bold", paddingBottom: "25px" }} gutterBottom>
            {/* Find an inspiring work community in minutes */}
            {/* Working from home sucks.... */}
            {/* You're not stuck at home ... */}
            {/* Find your great work place */}
            Discover a great work spot today
        </Typography>
        <SearchControlsPanel {...props}/>
    </>)
}

export const SearchControlsPanel = (props: SearchControlsParams) => {
    const {value:v} = props
    const hideButton = props.hideButton ?? false

    const [date, handleDateChange] = React.useState<any | null>(v?.date ? dateToMoment(v.date): moment())
    const [time, handleTimeChange] = React.useState<any | null>(moment().hours(20).minutes(0))  // TODO: Fix me
    const [humanNeeds, sethumanNeeds] = React.useState<Record<string, boolean>>(v?.humanNeeds ? associate<string, boolean>(v.humanNeeds, (id)=>[id, true]) : {}) // TODO: fix this to show selections
    const [budget, setBudget] = React.useState<BudgetLevel | undefined>(v?.budget ? allBudgetLevels.find(l=>l.id == v.budget) : allBudgetLevels[0])
    const [location, setLocation] = React.useState<string>(v?.location ?? "91320")
    const [wifiLevel, setWifiLevel] = React.useState<string | undefined>(v?.wifiLevel ?? goodWifi)
    const [ergoLevel, setErgoLevel] = React.useState<string | undefined>(v?.ergoLevel ?? anyErgo)
    const [requiresPower, setRequiresPower] = React.useState(v?.requiresPower ?? true)
    const [requiresPrinter, setRequiresPrinter] = React.useState(v?.requiresPrinter ?? false)
    const [requiresDrink, setRequiresDrink] = React.useState(v?.requiresDrink ?? false)
    const [requiresFood, setRequiresFood] = React.useState(v?.requiresFood ?? false)
    const [requiresBathroom, setRequiresBathroom] = React.useState(v?.requiresBathroom ?? true)
    const [showAdvanced, setShowAdvanced] = React.useState(false)
    const [workMode, setWorkMode] = React.useState<WorkModeWrapper|undefined>(v?.workMode ? allWorkModes.find(i=>i.id == v.workMode)!! : ComputerWork)
    const showFull = props.showFull ?? false


    const selectedNeeds:HumanNeed[] = Object.keys(humanNeeds).flatMap(id=>{
        const need = allNeeds.find(n=>n.id == id)!!
        if(humanNeeds[id]){
            return [need]
        }else{
            return []
        }
    })

    console.log("selected needs are", selectedNeeds)

    console.log("Value is ", date, v)

    const compositeValue: SearchParams = {
        date: momentToDate(date),
        location: location,
        until: time?.toString(),
        humanNeeds: selectedNeeds.map(n=>n.id),
        budget: budget?.id,
        wifiLevel: wifiLevel,
        ergoLevel: ergoLevel,
        requiresPower: requiresPower,
        requiresPrinter: requiresPrinter,
        requiresDrink: requiresDrink,
        requiresFood: requiresFood,
        requiresBathroom: requiresBathroom,
        workMode:workMode?.id ?? ComputerWork.id,
    }

    console.log(`compositeValue`, compositeValue)

    const handleSubmit = () => {
        console.log("hi")
        compositeValue && props.onClick(compositeValue)
    }

    const toggleAdvanced = () => setShowAdvanced(!showAdvanced)

    const fcStyle: CSSProperties = { textAlign: "left" }
    const gridStyle: CSSProperties = { textAlign: "left" }
    const gridXs = 6
    const gridSm = 4

    const primaryFieldSxProps: SxProps<Theme> = {
        m: 0,
    }

    const breakPoint = useBreakpoint()

    const isExtraSmall = breakPoint ? isAtOrBelow(breakPoint, Breakpoint.xs,) : false

    console.log("MY size is ", breakPoint, "isExtraSmall?", isExtraSmall)

    const showNeedsSelector = workMode?.id == ComputerWork.id

    const needsSelector = (
        <CenteredMaxWidthContentBox maxWidth={"1024px"}>
            <Stack spacing={3} >
                <FormControl>
                    <FormGroup>
                        <OptionsList
                            label="What do you need today?"
                            disabled={!showNeedsSelector}
                            options={allNeeds}
                            optionLabel={(v: HumanNeed) => v.name}
                            optionKey={(v: HumanNeed) => v.id}
                            setValue={(v) => {
                                console.log("New HumanNeed", v)

                                sethumanNeeds(v)
                            }}
                            value={humanNeeds} />
                    </FormGroup>
                </FormControl>
            </Stack>
        </CenteredMaxWidthContentBox>
    )

    const needsSelect:ReactNode = isExtraSmall ? (
        <LabeledSelect
            label="Your Mission"
            value={workMode?.id}
            onChange={v => setWorkMode(allWorkModes.find(i=>i.id == v.target.value))}
            fullWidth={true}
        >
            {allWorkModes.map(mode => <MenuItem value={mode.id}>{mode.label}</MenuItem>)}
        </LabeledSelect>
    ) : (
        <ExclusiveList
            label="Your Mission"
            optionKey={e => e.id}
            optionLabel={e => e.label}
            options={allWorkModes}
            value={workMode}
            setValue={setWorkMode}
        />
    )

    const [first, second]:ReactNode[] = isExtraSmall ? [needsSelect, needsSelector] : [needsSelector, needsSelect]

    return (<>
        <Stack spacing={3} >

            <Grid container spacing={3} justifyContent={"flex-start"} >
                <Grid xs={12} sm={6} >
                    <Box style={{ textAlign: "left" }}>
                    {first}
                    </Box>
                </Grid>
                <Grid xs={12} sm={6} >
                    <Box style={{ textAlign: "left" }}>
                      {second}
                    </Box>
                </Grid>
                <Grid xs={12} sm={6}>
                    <DatePicker
                        label="Date"
                        value={date}
                        onChange={handleDateChange}
                        defaultValue={today()}
                        slotProps={{
                            textField: {
                                variant: 'standard',
                                fullWidth: true,
                                sx: primaryFieldSxProps,
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CalendarMonthIcon />
                                        </InputAdornment>
                                    ),
                                },
                            }
                        }} />
                </Grid>
                <Grid xs={12} sm={6}>
                    <FormControl sx={primaryFieldSxProps} variant="standard"
                        fullWidth={true}>
                        <InputLabel htmlFor="standard-adornment-password">Your Location</InputLabel>
                        
                        <Input
                            value={location}
                            onChange={e => setLocation(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    {/* <IconButton
                                    aria-label="toggle password visibility"
                                    > */}
                                    <NearMeIcon />
                                    {/* </IconButton> */}
                                </InputAdornment>
                            } />
                    </FormControl>
                </Grid>

            </Grid>

            {(!showFull && !showAdvanced )&& <Box>
                <Button endIcon={<TuneIcon />} onClick={toggleAdvanced}>Configure</Button>
            </Box>}
            {(showFull || showAdvanced) && <Box>
                {!showFull && <Divider >
                    <Button endIcon={<TuneIcon />} onClick={toggleAdvanced}>Configure</Button>
                </Divider>}
                <Grid container
                    style={{ margin: "20px" }}
                    spacing={4}
                    display="inline-flex"
                    justifyContent="flex-start"
                    alignItems="flex-start">

                    <Grid xs={gridXs} sm={gridSm} style={gridStyle}>
                        <FormControl style={fcStyle}>

                            <ExclusiveList
                                label="Budget"
                                optionKey={(n: BudgetLevel) => n.id}
                                optionLabel={(n: BudgetLevel) => n.name}
                                options={allBudgetLevels}
                                value={budget}
                                setValue={setBudget}
                            />
                        </FormControl>

                    </Grid>
                    <Grid xs={gridXs} sm={gridSm} style={gridStyle}>

                        <FormControl style={fcStyle}>
                            <ExclusiveList
                                label="Wifi"
                                optionKey={n => n}
                                optionLabel={n => n}
                                options={allWifiLevels}
                                value={wifiLevel}
                                setValue={setWifiLevel}
                            />
                        </FormControl>

                    </Grid>
                    <Grid xs={gridXs} sm={gridSm} style={gridStyle}>
                        <FormControl style={fcStyle}>
                            <ExclusiveList
                                label="Ergonomics"
                                optionKey={n => n}
                                optionLabel={n => n}
                                options={allErgoLevels}
                                value={ergoLevel}
                                setValue={setErgoLevel}
                            />
                        </FormControl>

                    </Grid>
                    <Grid xs={gridXs} sm={gridSm} style={gridStyle}>
                        <FormGroup style={fcStyle}>
                            <FormLabel id="demo-radio-buttons-group-label">Equipment</FormLabel>
                            <DiscreteOption label="Power" value={requiresPower} setValue={setRequiresPower} />
                            <DiscreteOption label="Printer" value={requiresPrinter} setValue={setRequiresPrinter} />
                        </FormGroup>
                    </Grid>
                    <Grid xs={gridXs} sm={gridSm} style={gridStyle}>
                        <FormGroup style={fcStyle}>
                            <FormLabel id="demo-radio-buttons-group-label">Bio</FormLabel>
                            <DiscreteOption label="Bathroom" value={requiresBathroom} setValue={setRequiresBathroom} />
                            <DiscreteOption label="Coffee/Drink" value={requiresDrink} setValue={setRequiresDrink} />
                            <DiscreteOption label="Food" value={requiresFood} setValue={setRequiresFood} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>}


            {hideButton ?
                <Paper style={{ background: "white", width: "100%", position: "fixed", bottom: "0px", right: "0px", textAlign: "right" }} >

                    <Button variant="contained" style={{ margin: "10px" }} onClick={handleSubmit}>Search</Button>
                </Paper> : <Button variant="contained" onClick={handleSubmit}>Search</Button>}
        </Stack>
    </>)
}


export default TitledSearchControlsPanel