import moment, { Moment } from "moment"
import { SimpleYearMonthDay } from "./workspots/Api"

export const today = ():SimpleYearMonthDay => momentToDate(moment())

export const momentToDate = (m:Moment):SimpleYearMonthDay => {
    const d:SimpleYearMonthDay = {
        year: m.year(),
        month: m.month() + 1,
        day: m.date()
    }

    console.log("Got ", d, "from", m)

    return d
}

export const dateToMoment = (d:SimpleYearMonthDay):Moment => {
    return moment()
        .year(d.year)
        .month(d.month - 1)
        .date(d.day)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
}